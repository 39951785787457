export default {

    youtubeUser: {
        name: "YouTube User",
        link: "https://youtube.com/user/",
        icon: 'fab fa-youtube'
    },
    youtubeChannel: {
        name: "YouTube Channel",
        link: "https://youtube.com/channel/",
        icon: "fab fa-youtube",
    },
    twitter: {
        name: "Twitter",
        link: "https://twitter.com/",
        icon: "fab fa-twitter"
    },
    twitch: {
        name: "Twitch",
        link: "https://twitch.tv/",
        icon: "fab fa-twitch"
    }

}