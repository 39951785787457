import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import * as validator from "validator";
import {withFirebase} from "../components/Firebase";

class Register extends Component {

    state = {
        loading: false,
        error: null
    };

    render() {
        return (
            <div>
                <ProfileHero color={this.state.error ? 'red' : 'orange'}>
                    <h1 className='text-white font-bold text-5xl'>
                        Create an Account
                    </h1>
                    <p className={(this.state.error ? 'font-bold ' : '') + 'text-xl text-white pb-12'}>{this.state.error ? this.state.error :
                        "Create an account to make pages, favorite products, and more!"}</p>
                </ProfileHero>
                <div className='-mt-24 max-w-xl mx-auto'>
                    <ProfileContent>
                        <SmartForm
                            fields={{
                                email: {
                                    label: "Email Address",
                                    type: 'text',
                                    hint: "Won't be shown publicly - you'll use this for sign in.",
                                    placeholder: "me@example.com",
                                    required: true,
                                    validations: [
                                        {
                                            isValid: input => validator.isEmail(input),
                                            error: "Email address isn't valid."
                                        }
                                    ]
                                },
                                password: {
                                    label: "Password",
                                    type: "password",
                                    hint: "Pick something that's at least 8 characters.",
                                    required: true,
                                    validations: [
                                        {
                                            isValid: input => input.length >= 8,
                                            error: "Password must be at least 8 characters!"
                                        }
                                    ]
                                },
                                confirmPassword: {
                                    label: "Verify Password",
                                    type: "password",
                                    required: true,
                                    validations: [
                                        {
                                            isValid: (input, fields) => input === fields.password,
                                            error: "Password doesn't match verification!"
                                        }
                                    ]
                                },
                                username: {
                                    label: "Username",
                                    type: "text",
                                    hint: "Will be public. You can change this later.",
                                    required: true,
                                    maxLength: 16,
                                    validations: [
                                        {
                                            isValid: input => validator.isAlphanumeric(input),
                                            error: "Username can only contain letters and numbers!"
                                        }
                                    ]
                                }
                            }}
                            button={() => {
                                return <span><i className='fas fa-user-plus'></i> Create Account</span>
                            }}
                            loading={this.state.loading}
                            submit={input => {
                                this.setState({loading: true, error: null})
                                this.props.firebase.createUser(input.email, input.password, input.username).then(() => {
                                    alert("Account created!")
                                }).catch(e => {
                                    this.setState({
                                        error: e.message,
                                        loading: false
                                    })
                                });
                            }}
                        />
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withFirebase(Register);