import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import {withFirebase} from "../components/Firebase";
import ProfileGearCategory from "../components/ProfileGearCategory/ProfileGearCategory";

class EditProfileGame extends Component {

    state = {
        loading: false,
        prefProfile: null,
        prefProfileId: null
    };

    componentDidMount() {
        if (this.props.profile) {
            this.loadPrefProfile();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.profile && this.props.profile) || (prevProps.profile !== this.props.profile)) {
            this.loadPrefProfile();
        }
    }

    createPrefProfile = async () => {

        const gameId = this.props.match.params.gameId;
        const gameData = this.props.profile.games.find(gameEntry => {
            return gameEntry.game === gameId;
        });

        const createdProfileRef = await this.props.firebase.createPrefProfile(gameData.game);

        const copiedEntry = {...gameData};
        copiedEntry.prefProfile = createdProfileRef.id;
        let games = [...this.props.profile.games];
        games = games.filter(gameEntry => {
            return gameEntry.game !== gameId;
        });
        games.push(copiedEntry);
        await this.props.firebase.updateProfile(this.props.profile.url, {
            games
        });
        window.location.reload();

    };

    loadPrefProfile = async () => {

        if (this.state.prefProfile) {
            return;
        }

        const gameId = this.props.match.params.gameId;
        const gameData = this.props.profile.games.find(gameEntry => {
            return gameEntry.game === gameId;
        });

        if (gameData.prefProfile) {
            const prefProfile = await this.props.firebase.getPrefProfile(gameData.prefProfile);
            if (prefProfile.exists) {
                this.setState({
                    prefProfile: prefProfile.data(),
                    prefProfileId: prefProfile.id
                });
            }
        }

    };

    render() {

        const games = this.props.games;
        const gameId = this.props.match.params.gameId;
        const matchingGame = games.find(gameDoc => {
            return gameDoc.id === gameId;
        });

        if (!matchingGame || !this.props.profile) {
            return null;
        }

        const gameData = this.props.profile.games.find(gameEntry => {
            return gameEntry.game === gameId;
        });

        return (
            <React.Fragment>
                <NavLink to={'/' + this.props.profile.url + "/edit"}
                         className='bg-white px-4 py-2 rounded-lg text-orange-500 hover:text-orange-400 mt-6 inline-block'>
                    <i className='fas fa-chevron-left'></i> Go back
                </NavLink>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i className='fas fa-cog'></i> {matchingGame.data.name} Info
                    </h3>
                    <SmartForm
                        fields={{
                            ign: {
                                type: 'text',
                                required: true,
                                label: 'In-Game Name',
                                value: gameData.ign,
                                hint: "If you've changed your username for " + matchingGame.data.name + ", you can update it here."
                            }

                        }}
                        loading={this.state.loading}
                        button={() => {
                            return <span><i className='fas fa-check'></i> Save</span>
                        }}
                        submit={async input => {

                            const copiedEntry = {...gameData};
                            copiedEntry.ign = input.ign;
                            let games = [...this.props.profile.games];
                            games = games.filter(gameEntry => {
                                return gameEntry.game !== gameId;
                            });
                            games.push(copiedEntry);
                            await this.props.firebase.updateProfile(this.props.profile.url, {
                                games
                            });
                            window.location.reload();

                        }}
                    />
                </ProfileContent>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i
                        className='fas fa-wrench'></i> {matchingGame.data.name} Settings Profile</h3>
                    {this.state.prefProfile ? (
                        <React.Fragment>
                            {matchingGame.data.prefCategories.map(category => {

                                    const categorySettings = matchingGame.data.prefs.filter(pref => {
                                        return pref.category === category;
                                    });

                                    const formFields = {};
                                    categorySettings.forEach(setting => {
                                        formFields[setting.settingName] = {
                                            label: setting.settingName,
                                            required: setting.settingRequired,
                                            type: setting.settingType !== 'key' ? setting.settingType : 'shortcut',
                                            value: this.state.prefProfile[category+"-"+setting.settingName] || ''
                                        }
                                    });

                                    return (
                                        <ProfileGearCategory key={category} title={category} contracted>
                                            <SmartForm
                                                fields={formFields}
                                            />
                                        </ProfileGearCategory>
                                    );

                                }
                            )}
                        </React.Fragment>

                    ) : (
                        <div className='text-center py-16 max-w-lg mx-auto'>
                            <i className='fas fa-wrench text-5xl py-6'></i>
                            <h3 className='font-semibold mb-2 text-2xl'>Create a Settings Profile</h3>
                            <p>If you use special settings when playing {matchingGame.data.name}, you can add them to
                                your profile for others to try!</p>
                            <button onClick={async () => {

                                await this.createPrefProfile();

                            }}
                                    className='my-4 px-4 py-2 w-full bg-orange-500 hover:bg-orange-400 text-white rounded-lg font-bold'>
                                <i className='fas fa-plus'></i> Create Settings Profile
                            </button>
                        </div>
                    )}
                </ProfileContent>
            </React.Fragment>
        );
    }

}

export default withRouter(withFirebase(EditProfileGame));