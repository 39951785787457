import React, {Component} from 'react';

class ProfileGearCategory extends Component {

    state = {
        expanded: !this.props.contracted
    };

    toggleExpanded = () => {
        this.setState({expanded: !this.state.expanded})
    };

    render() {

        return (
            <div className='mt-4'>
                <button onClick={this.toggleExpanded} className={'text-left bg-orange-500 w-full ' + (this.state.expanded?'rounded-t-lg':'rounded-lg') + ' text-white px-4 py-2 font-bold text-xl'}>
                    <i className={'fa fa-chevron-' + (this.state.expanded?'down':'right')}></i> {this.props.title}
                </button>
                {this.state.expanded?(
                    <div className='bg-orange-100 border border-orange-500 rounded-b-lg p-6'>
                        {this.props.children}
                    </div>
                ):null}
            </div>
        );
    }

}

export default ProfileGearCategory;