import React from 'react';

const blogCard = props => {
    const blogPost = props.blogPost;
    return(
        <div className='bg-orange-200 shadow-lg border border-orange-500 rounded-lg'>
            <img className='w-full h-24 object-cover' src={blogPost.feature_image} alt={blogPost.title}/>
            <div className='p-4 text-orange-600'>
                <h3 className='font-bold text-lg no-wrap truncate'>{blogPost.title}</h3>
                <p>{blogPost.custom_excerpt||blogPost.excerpt}</p>
                <a href={blogPost.url} target='_blank' className='block text-center px-4 py-2 bg-orange-500 hover:bg-orange-400 text-white font-bold mt-2 rounded-lg'>
                    Read more <i className='fas fa-arrow-right'></i>
                </a>
            </div>
        </div>
    );

};

export default blogCard;