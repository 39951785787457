import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import {withFirebase} from "../components/Firebase";
import {NavLink, Redirect, Route, Switch, withRouter} from "react-router-dom";
import socials from "../utils/socials";
import * as moment from 'moment';
import ProfileGearCategory from "../components/ProfileGearCategory/ProfileGearCategory";
import EditProfileGame from "./EditProfileGame";
import {withUser} from "../components/Session";
import DeviceSearch from "../components/DeviceSearch/DeviceSearch";
import DeviceCard from "../components/DeviceCard/DeviceCard";

class EditProfile extends Component {

    state = {
        loading: false,
        error: null,
        profile: null,
        redirect: false,
        games: [],
        addedRedirect: null
    };

    fileSelected = async files => {

        if (files.length === 1) {
            const file = files[0];
            await this.props.firebase.setProfilePicture(this.state.profile, file);
            window.location.reload();
        }

    };

    componentDidMount() {
        const gameUrl = this.props.match.params.profileUrl;
        this.loadProfile(gameUrl);
    }

    loadProfile = async url => {
        const result = await this.props.firebase.getProfileByUrl(url);
        if (result.exists) {
            this.setState({
                profile: result.data()
            }, async () => {

                const results = await this.props.firebase.getGames();

                const games = [];
                results.forEach(result => {
                    games.push({
                        id: result.id,
                        data: result.data()
                    })
                });
                this.setState({games});

            })
        } else {
            this.setState({
                redirect: true
            })
        }
    };

    removeDeviceFromProfile = async deviceId => {

        const profile = {...this.state.profile};

        const updatedDevices = profile.devices.filter(device => {
            return device !== deviceId;
        });

        profile.devices = [...updatedDevices];
        this.setState({profile});
        await this.props.firebase.updateProfile(this.state.profile.url, {
            devices: [...profile.devices]
        });

    };

    render() {

        if (this.state.profile === null) {
            if (this.state.redirect) {
                return <Redirect to='/'/>
            }
            return (
                <div className='py-48 text-center text-3xl'>
                    <i className='fas fa-spinner fa-spin'></i>
                </div>
            );
        }

        if (this.state.addedRedirect && this.state.profile) {
            return <Redirect to={'/' + this.state.profile.url + "/edit/" + this.state.addedRedirect}/>
        }

        let socialMap = {};
        Object.keys(socials).forEach(socialKey => {
            socialMap[socialKey] = socials[socialKey].name;
        });

        let gamesMap = {};
        if (this.state.games) {
            this.state.games.forEach(gameEntry => {
                gamesMap[gameEntry.id] = gameEntry.data.name;
            })
        }

        let profileContent = (
            <React.Fragment>
                {!this.state.profile.published?(
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i className='fas fa-globe'></i> Publish Page</h3>
                        <div className='flex flex-wrap items-center'>
                            <div className='w-3/4'>
                                <p>Your page isn't available to the public until you publish it.</p>
                                {this.props.user&&!this.props.user.emailVerified?(
                                    <p className='font-semibold text-red-500'>You need to verify your email, first. <NavLink className='text-orange-500 underline hover:text-orange-400' to='/account'>My Account &gt;</NavLink></p>
                                ):null}
                            </div>
                            <div className='w-1/4'>
                                <button onClick={async ()=>{

                                    try {
                                        await this.props.firebase.updateProfile(this.state.profile.url, {
                                            published: true
                                        });
                                        window.location.reload();
                                    }catch (e) {
                                        alert(e.message);
                                    }

                                }} className={(!this.props.user||!this.props.user.emailVerified?'cursor-not-allowed opacity-50 ':'hover:bg-orange-400') + ' bg-orange-500 font-semibold w-full text-white px-4 py-2 rounded-lg'}>
                                    Publish Now
                                </button>
                            </div>
                        </div>
                    </ProfileContent>
                ):null}
                <ProfileContent>
                    <div className='flex flex-wrap'>
                        <div className='w-1/3 px-2 text-center'>
                            <h3 className='font-bold text-xl mb-4'><i className='fas fa-image'></i> Profile Image
                            </h3>
                            <label>
                                {this.state.profile.profileUrl ? (
                                    <img src={this.state.profile.profileUrl} alt={this.state.profile.name}
                                         className='hover:border-2 border-red-500 rounded-full shadow-lg w-32 h-32 object-cover inline-block cursor-pointer'/>
                                ) : (
                                    <div
                                        className='bg-gray-300 cursor-pointer rounded-full hover:bg-gray-400 w-3/4 inline-block'>
                                        <i className='fas fa-image text-xl py-20'></i>
                                    </div>
                                )}
                                <input onChange={e => {
                                    this.fileSelected(e.target.files)
                                }} accept='image/png' type='file' className='hidden'/>
                            </label>
                            <p className='text-gray-600 mt-4 font-semibold text-lg'>Select a .png that will be your
                                profile image.</p>
                        </div>
                        <div className='w-2/3 px-2'>
                            <h3 className='font-bold text-xl mb-4'><i className='fas fa-edit'></i> Basic Info</h3>
                            <SmartForm
                                fields={{
                                    url: {
                                        label: "Page URL",
                                        placeholder: 'mysetup.gg/...',
                                        hint: "This is the link people will visit to go to your page. It cannot be changed.",
                                        value: this.state.profile.url,
                                        disabled: true
                                    },
                                    name: {
                                        label: "Name",
                                        placeholder: "John Doe",
                                        hint: "This is the real name, or first name of who this profile is for.",
                                        required: true,
                                        maxLength: 16,
                                        value: this.state.profile.name
                                    },
                                    handle: {
                                        label: "Handle",
                                        placeholder: "Ahadify",
                                        hint: "The social handle or username this person is most known by",
                                        required: true,
                                        maxLength: 24,
                                        value: this.state.profile.handle
                                    }
                                }}
                                button={() => {
                                    return <span><i className='fas fa-check'></i> Save</span>
                                }}
                                loading={this.state.loading}
                                submit={input => {
                                    this.setState({loading: true, error: null});
                                    this.props.firebase.updateProfile(this.state.profile.url, {
                                        name: input.name,
                                        handle: input.handle
                                    }).then(() => {
                                        window.location.reload();
                                    }).catch(e => {
                                        this.setState({
                                            error: e.message,
                                            loading: false
                                        })
                                    });
                                }}
                            />
                        </div>
                    </div>

                </ProfileContent>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i className='fas fa-link'></i> Social Links</h3>
                    {this.state.profile.socials.length === 0 ? (
                        <div className='text-center py-16'>
                            <p className='text-red-600'>There aren't any social links added yet!</p>
                        </div>
                    ) : (
                        <table className='w-full my-4 mb-12 text-left'>
                            <thead>
                            <tr className='text-lg font-bold'>
                                <th>Site</th>
                                <th>Link</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.profile.socials.map(social => {

                                const socialData = socials[social.social];
                                if (!socialData) {
                                    return null;
                                }

                                const url = socialData.link + social.url;

                                return (
                                    <tr key={social.social + social.url}>
                                        <td><i className={socialData.icon}></i> {socialData.name}</td>
                                        <td><a href={url} target='_blank'
                                               className='text-orange-500 hover:underline'>{url}</a></td>
                                        <td>
                                            <button className='text-sm text-red-500 hover:text-red-400 underline'><i
                                                className='fas fa-trash'></i> Delete
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    )}
                    <ProfileGearCategory title='Add Social Site' contracted>
                        <SmartForm
                            fields={{
                                social: {
                                    type: 'select',
                                    label: "Social Site",
                                    hint: "What site do you want to link?",
                                    options: socialMap
                                },
                                url: {
                                    type: 'text',
                                    label: "Username",
                                    hint: "Enter your username or handle, we'll put together the rest for you.",
                                    required: true,
                                    validations: [
                                        {
                                            isValid: (input, fields) => {
                                                if (this.state.profile.socials) {
                                                    const match = this.state.profile.socials.find(social => {
                                                        return social.url.toLowerCase() === input.toLowerCase() && social.social === fields.social;
                                                    })
                                                    return !match;
                                                }
                                                return true;
                                            },
                                            error: "You've already added this link!"
                                        }
                                    ]
                                },
                                label: {
                                    type: 'text',
                                    label: "Label",
                                    hint: "If you want to show something different from your username. (Optional)"
                                }

                            }}
                            loading={this.state.loading}
                            button={() => {
                                return <span><i className='fas fa-plus'></i> Add Link</span>
                            }}
                            submit={input => {
                                this.setState({loading: true, error: null});

                                const social = input.social;
                                const url = input.url;
                                const label = input.label || "";


                                const profile = {...this.state.profile};
                                if (!profile.socials) {
                                    profile.socials = [];
                                }
                                profile.socials.push({
                                    social,
                                    url,
                                    label
                                });
                                this.setState({profile});

                                this.props.firebase.updateProfile(profile.url, {
                                    socials: [...profile.socials]
                                }).then(() => {
                                    this.setState({
                                        loading: false
                                    })
                                }).catch(e => {
                                    this.setState({
                                        error: e.message,
                                        loading: false
                                    })
                                });
                            }}
                        />
                    </ProfileGearCategory>

                </ProfileContent>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i className='fas fa-gamepad'></i> Games</h3>
                    {this.state.profile.games ? (
                        <table className='text-left w-full mb-16'>
                            <thead>
                            <tr>
                                <th>Game</th>
                                <th>IGN</th>
                                <th>Added</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.profile.games.map(game => {

                                const gameData = this.state.games.find(gameDoc => {
                                    return gameDoc.id === game.game
                                });

                                if (!gameData) {
                                    return null;
                                }

                                return (
                                    <tr key={game.game}>
                                        <td>{gameData.data.name}</td>
                                        <td>{game.ign}</td>
                                        <td>{moment(game.added.seconds * 1000).fromNow()}</td>
                                        <td>
                                            <NavLink
                                                to={'/' + this.state.profile.url + "/edit/" + game.game}
                                                className='px-2 text-sm text-orange-500 hover:text-orange-400 underline'
                                            ><i className='fa fa-wrench'></i> Configure</NavLink>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    ) : null}
                    <ProfileGearCategory title='Add Game' contracted>
                        <SmartForm
                            fields={{
                                game: {
                                    type: 'select',
                                    label: "Game",
                                    required: true,
                                    options: gamesMap,
                                    validations: [
                                        {
                                            isValid: input => {
                                                if (this.state.profile.games) {
                                                    const match = this.state.profile.games.find(gameEntry => {
                                                        return gameEntry.game === input;
                                                    });
                                                    if (match) {
                                                        return false;
                                                    }
                                                }
                                                return true;
                                            },
                                            error: "This game is already added to your library!"
                                        }
                                    ]
                                },
                                ign: {
                                    type: 'text',
                                    label: "In-Game Name",
                                    required: true,
                                    hint: "Your in-game username for this game."
                                }
                            }}
                            loading={this.state.loading}
                            button={() => {
                                return <span>Add &amp; Configure <i className='fas fa-arrow-right'></i></span>
                            }}
                            submit={async input => {

                                const profile = {...this.state.profile};
                                if (!profile.games) {
                                    profile.games = [];
                                }
                                if (!profile.gameIds){
                                    profile.gameIds = [];
                                }

                                const coverUrl = await this.props.firebase.getGameCover(input.game);

                                profile.games.push({
                                    game: input.game,
                                    ign: input.ign,
                                    added: new Date(),
                                    updated: new Date(),
                                    prefProfile: null,
                                    coverUrl
                                });
                                profile.gameIds.push(input.game);
                                this.setState({profile});
                                await this.props.firebase.updateProfile(this.state.profile.url, {
                                    games: [...profile.games],
                                    gameIds: [...profile.gameIds]
                                });
                                this.setState({
                                    addedRedirect: input.game
                                })
                            }}
                        />
                    </ProfileGearCategory>
                </ProfileContent>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i className='fas fa-mouse'></i> Gear &amp; Devices</h3>
                    <div className='flex flex-wrap items-center'>
                        {this.state.profile.devices?this.state.profile.devices.map(deviceId => {
                            return (
                                <div className='w-1/4 px-2' key={deviceId}>
                                    <DeviceCard removeDevice={this.removeDeviceFromProfile} deviceId={deviceId}/>
                                </div>
                            );
                        }):null}
                    </div>
                    <ProfileGearCategory title='Add Device' contracted>
                        <DeviceSearch
                            addDevice={async deviceId => {

                                const profile = {...this.state.profile};
                                if (!profile.devices) {
                                    profile.devices = [];
                                }
                                profile.devices.push(deviceId);
                                this.setState({profile});
                                await this.props.firebase.updateProfile(this.state.profile.url, {
                                    devices: [...profile.devices]
                                });

                            }}
                            removeDevice={this.removeDeviceFromProfile}
                            devices={this.state.profile.devices||[]}
                        />
                    </ProfileGearCategory>
                </ProfileContent>
            </React.Fragment>
        );

        return (
            <div>
                <ProfileHero color={this.state.error ? 'red' : 'orange'}>
                    <h1 className='text-white pb-12 font-bold text-5xl'>
                        Edit: {this.state.profile.name}
                    </h1>
                </ProfileHero>
                <div className='-mt-24 max-w-3xl mx-auto'>
                    <Switch>
                        <Route path={'/' + this.state.profile.url + "/edit/:gameId"} render={() => {
                            return <EditProfileGame profile={this.state.profile} games={this.state.games}/>;
                        }}/>
                        <Route path={'/' + this.state.profile.url + "/edit"} render={() => {
                            return profileContent;
                        }}/>
                    </Switch>
                </div>
            </div>
        );
    }

}

export default withRouter(withFirebase(withUser(EditProfile)));