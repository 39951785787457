import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import {withUser} from "../components/Session";
import * as validator from "validator";
import {withFirebase} from "../components/Firebase";
import {NavLink} from "react-router-dom";

class Account extends Component {

    state = {
        authenticated: false,
        loading: false,
        error: null,
        verifySent: false
    };

    render() {

        if (!this.state.authenticated){
            return (
                <div>
                    <ProfileHero color={this.state.error?'red':'gray'}>
                        <h1 className='text-white font-bold text-5xl'>
                            My Account
                        </h1>
                        <p className='text-xl text-white pb-12'>
                            {this.state.error||"Please confirm your password to continue."}
                        </p>
                    </ProfileHero>
                    <div className='-mt-24 max-w-xl mx-auto'>
                        <ProfileContent>
                            <SmartForm
                                fields={{
                                    password: {
                                        label: "Password",
                                        type: "password",
                                        required: true
                                    }
                                }}
                                button={() => {
                                    return <span>Continue <i className='fas fa-arrow-right'></i></span>
                                }}
                                loading={this.state.loading}
                                submit={input => {
                                    this.setState({loading: true, error: null});
                                    this.props.firebase.signIn(this.props.user.email, input.password).then(() => {
                                        this.setState({
                                            loading: false,
                                            authenticated: true
                                        })
                                    }).catch(e => {
                                        this.setState({
                                            error: e.message,
                                            loading: false
                                        })
                                    });
                                }}
                            />
                        </ProfileContent>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <ProfileHero>
                    <h1 className='text-white font-bold text-5xl'>
                        My Account
                    </h1>
                    <p className='text-xl text-white pb-12'>Update your email, password, or username</p>
                </ProfileHero>
                <div className='-mt-24 max-w-xl mx-auto'>
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i className='fas fa-user'></i> Change Username</h3>
                        <SmartForm
                            fields={{
                                currentUsername: {
                                    label: "Current Username",
                                    type: 'text',
                                    disabled: true,
                                    value: this.props.user ? this.props.user.displayName : ""
                                },
                                username: {
                                    label: "New Username",
                                    type: "text",
                                    hint: "Can only contain letters and numbers.",
                                    required: true,
                                    maxLength: 16,
                                    validations: [
                                        {
                                            isValid: input => validator.isAlphanumeric(input),
                                            error: "Username can only contain letters and numbers!"
                                        }
                                    ]
                                }
                            }}
                            button={() => {
                                return <span><i className='fas fa-check'></i> Save</span>
                            }}
                            loading={this.state.loading}
                            submit={input => {
                                this.setState({loading: true, error: null});
                                this.props.firebase.changeUsername(input.username).then(() => {
                                    this.setState({
                                        loading: false
                                    })
                                }).catch(e => {
                                    this.setState({
                                        error: e.message,
                                        loading: false
                                    })
                                });
                            }}
                        />
                    </ProfileContent>
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i className='fas fa-envelope'></i> Change Email</h3>
                        {!this.props.user.emailVerified?(
                            <div className='bg-red-500 px-4 py-2 rounded-lg text-white font-semibold mt-6'>
                                <p><strong>Email Not Verified </strong>Some activities are limited until you verify your email. {this.state.verifySent?(
                                    <button className='font-bold underline cursor-not-allowed' disabled>Sent!</button>
                                ):(
                                    <button onClick={() => {
                                        this.props.firebase.sendEmailVerification().then(() => {
                                            this.setState({verifySent: true})
                                        })
                                    }} className='font-bold underline'>Send Verification &gt;</button>
                                )}</p>
                            </div>
                        ):null}
                        <SmartForm
                            fields={{
                                currentEmail: {
                                    label: "Current Email",
                                    type: 'text',
                                    disabled: true,
                                    value: this.props.user ? this.props.user.email : ""
                                },
                                email: {
                                    label: "New Email",
                                    type: "text",
                                    required: true,
                                    validations: [
                                        {
                                            isValid: input => validator.isEmail(input),
                                            error: "Email address must be valid"
                                        }
                                    ]
                                }
                            }}
                            button={() => {
                                return <span><i className='fas fa-check'></i> Save</span>
                            }}
                            loading={this.state.loading}
                            submit={input => {
                                this.setState({loading: true, error: null});
                                this.props.firebase.changeEmail(input.email).then(() => {
                                    this.setState({
                                        loading: false
                                    })
                                }).catch(e => {
                                    this.setState({
                                        error: e.message,
                                        loading: false
                                    })
                                });
                            }}
                        />
                    </ProfileContent>
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i className='fas fa-lock'></i> Change Password</h3>
                        <SmartForm
                            fields={{
                                password: {
                                    label: "New Password",
                                    type: 'password',
                                    hint: "Must be at least 8 characters.",
                                    required: true,
                                    validations: [
                                        {
                                            isValid: input => input.length >= 8,
                                            error: "Password must be at least 8 characters"
                                        }
                                    ]
                                },
                                confirmPassword: {
                                    label: "Verify Password",
                                    type: "password",
                                    required: true,
                                    validations: [
                                        {
                                            isValid: (input, fields) => input === fields.password,
                                            error: "Password doesn't match verification!"
                                        }
                                    ]
                                }
                            }}
                            button={() => {
                                return <span><i className='fas fa-check'></i> Save</span>
                            }}
                            loading={this.state.loading}
                            submit={input => {
                                this.setState({loading: true, error: null});
                                this.props.firebase.changePassword(input.password).then(() => {
                                    this.setState({
                                        loading: false
                                    })
                                }).catch(e => {
                                    this.setState({
                                        error: e.message,
                                        loading: false
                                    })
                                });
                            }}
                        />
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withUser(withFirebase(Account));