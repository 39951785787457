import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBVhSZPbb5oDYAynIDpLr7xHphaEXK2Jq8",
    authDomain: "setupgg-f4ed9.firebaseapp.com",
    databaseURL: "https://setupgg-f4ed9.firebaseio.com",
    projectId: "setupgg-f4ed9",
    storageBucket: "setupgg-f4ed9.appspot.com",
    messagingSenderId: "890378818028",
    appId: "1:890378818028:web:508fe8eb856e69e865b0c9",
    measurementId: "G-C9WZEGMBW8"
};

class Firebase {

    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.fs = app.firestore();
        this.storage = app.storage();
    }

    getDevices = categoryId => {
        return this.fs.collection('devices').where('categoryId', '==', categoryId).get();
    };

    setDeviceImage = async (deviceId, image) => {

        const time = new Date().getTime();
        const fileRef = this.storage.ref('devices/' + this.auth.currentUser.uid + "/" + (deviceId + time) + ".png");
        await fileRef.put(image, {
            customMetadata: {
                userId: this.auth.currentUser.uid,
                deviceId
            }
        });

        const downloadUrl = await fileRef.getDownloadURL();
        await this.updateDevice(deviceId, {
            imageUrl: downloadUrl
        })
    };

    setProfilePicture = async (page, image) => {
        const time = new Date().getTime();
        const fileRef = this.storage.ref('profiles/' + this.auth.currentUser.uid + "/" + (page.url + time) + ".png");

        await fileRef.put(image, {
            customMetadata: {
                userId: this.auth.currentUser.uid,
                pageUrl: page.url
            }
        });

        const downloadUrl = await fileRef.getDownloadURL();
        await this.fs.collection('profiles').doc(page.url).update({
            profileUrl: downloadUrl
        });
        return downloadUrl;
    };

    setGameCover = (gameId, image) => {
        return this.storage.ref('games/' + gameId + ".png").put(image, {
            customMetadata: {
                userId: this.auth.currentUser.uid
            }
        });
    };

    getPrefProfile = profileId => {
        return this.fs.collection('prefs').doc(profileId).get();
    };

    updatePrefProfile = (profileId, preferences) => {
        return this.fs.collection('prefs').doc(profileId).update({
            preferences
        })
    };

    createPrefProfile = gameId => {
        return this.fs.collection('prefs').add({
            gameId,
            creatorId: this.auth.currentUser.uid,
            preferences: null
        })
    };

    getGameCover = gameId => {
        return this.storage.ref('games/' + gameId + ".png").getDownloadURL();
    };

    getRecentProfiles = () => {
        return this.fs.collection('profiles').orderBy('updatedAt', 'desc').limit(4).get();
    };

    getGames = () => {
        return this.fs.collection('games').where('published', '==', true).get();
    };

    getGameByUrl = url => {
          return this.fs.collection('games').where('url', '==', url).limit(1).get();
    };

    updateGame = (gameId, data) => {
        return this.fs.collection('games').doc(gameId).update(data);
    };

    getProfileByUrl = url => {
        return this.fs.collection('profiles').doc(url.toLowerCase()).get();
    };

    updateProfile = (url, data) => {

        if (!data.updatedAt){
            data.updatedAt = new Date();
        }

        return this.fs.collection('profiles').doc(url.toLowerCase())
            .update(data);
    };

    getDeviceCategory = id => {
        return this.fs.collection('deviceCategories').doc(id).get();
    };

    getGamePlayers = gameId => {
        return this.fs.collection('profiles').where('gameIds', 'array-contains', gameId).get();
    };

    getDeviceUsers = deviceId => {
        return this.fs.collection('profiles').where('devices', 'array-contains', deviceId).get();
    };

    getDevice = id => {
        return this.fs.collection('devices').doc(id).get();
    };

    updateDevice = (deviceId, data) => {
        if (!data.updatedAt){
            data.updatedAt = new Date()
        }
        return this.fs.collection('devices').doc(deviceId).update(data);
    };

    createDevice = (categoryId, manufacturer, modelName, price) => {
        return this.fs.collection('devices').add({
            categoryId,
            manufacturer,
            modelName,
            price,
            creatorId: this.auth.currentUser.uid,
            createdAt: new Date(),
            updatedAt: new Date()
        })
    };

    updateDeviceCategory = (categoryId, data) => {
        return this.fs.collection('deviceCategories').doc(categoryId).update(data);
    };

    getDeviceCategories = () => {
        return this.fs.collection('deviceCategories').get();
    };

    createDeviceCategory = (icon, name) => {
        return this.fs.collection('deviceCategories').add({
            icon,
            name,
            creatorId: this.auth.currentUser.uid
        })
    };

    createProfile = (url, name, handle) => {

        const data = {
            name,
            url: url.toLowerCase(),
            handle,
            games: [],
            devices: [],
            socials: [],
            published: false,
            creatorId: this.auth.currentUser.uid,
            createdAt: new Date()
        };

        return this.fs.collection('profiles').doc(url.toLowerCase()).set(data);
    };

    createGame = (name, url) => {
        return this.fs.collection('games').add({
            name,
            url,
            creatorId: this.auth.currentUser.uid,
            createdAt: new Date(),
            imageUrl: null,
            settings: null
        })
    };

    changeEmail = newEmail => {
        return this.auth.currentUser.updateEmail(newEmail);
    };

    changePassword = newPassword => {
        return this.auth.currentUser.updatePassword(newPassword);
    };

    changeUsername = newUsername => {
        return this.auth.currentUser.updateProfile({displayName: newUsername})
    };

    sendEmailVerification = () => {
        return this.auth.currentUser.sendEmailVerification()
    };

    sendPasswordReset = email => {
        return this.auth.sendPasswordResetEmail(email);
    };

    signIn = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    };

    signOut = () => {
        return this.auth.signOut();
    };

    createUser = async (email, password, username) => {
        const newUser = await this.auth.createUserWithEmailAndPassword(email, password);
        await newUser.user.updateProfile({displayName: username});
        return newUser;
    };

}

export default Firebase;