import React, {Component} from 'react';
import {NavLink, Route} from "react-router-dom";
import Login from "../Login/Login";
import withAuthentication from "../Session/withAuthentication";
import {withUser} from "../Session";
import {withFirebase} from "../Firebase";

class Navbar extends Component {

    state = {
        login: false
    };

    render() {
        return (
            <div className='bg-white px-4 py-2 shadow-lg'>
                {this.state.login?<Login close={() => {this.setState({login: false})}}/>:null}
                <div className='max-w-5xl mx-auto'>
                    <div className='flex flex-wrap items-center'>
                        <div className='w-1/2 lg:w-1/4'>
                            <h3 className='text-xl lg:text-3xl font-black'><i
                                className='fas fa-gamepad pr-2'></i> MYSETUP<span className='font-thin'>.GG</span></h3>
                        </div>
                        <div className='w-1/2 lg:w-3/4 text-right'>
                            {this.props.user?(
                                <p>Hey, <NavLink className='font-bold hover:underline' to='/account'>{this.props.user.displayName}</NavLink>! (<button onClick={() => {
                                    if (window.confirm("Sign out?")){
                                        this.props.firebase.signOut();
                                    }
                                }} className='hover:underline'>Sign Out</button>)</p>
                            ):(
                                <p><button onClick={()=>{this.setState({login: true})}} className='bg-orange-500 px-2 py-1 text-white font-semibold rounded-lg'>Sign In</button> or <NavLink className='font-semibold hover:underline' to='/register'>Join for free!</NavLink></p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withUser(withFirebase(Navbar));