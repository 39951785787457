import React from "react";
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import ProfileGearCategory from "../components/ProfileGearCategory/ProfileGearCategory";

const verifyInfo = props => {

    return (
        <React.Fragment>
            <ProfileHero>
                <div className='text-white'>
                    <i className='fas fa-user-check text-5xl'></i>
                    <h3 className='text-3xl font-bold text-white py-4'>Page Verification</h3>
                </div>
            </ProfileHero>
            <div className='-mt-24 max-w-3xl mx-auto'>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i className='fas fa-question-circle'></i> What is verification?</h3>
                    <p>Page Verification places a checkmark on profiles that are officially owned and operated by the creator or brand a page is about. This usually means that the information, settings, or other info supplied on these pages is fully accurate and up to date.</p>
                </ProfileContent>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i className='fas fa-question-circle'></i> What does verification do?</h3>
                    <p>Verification displays a "Page Official" badge on the page in question. Pages that are not verified could be subject to ownership transfer should the verified owner wish to take it over.</p>
                </ProfileContent>
                <ProfileContent>
                    <h3 className='font-bold text-xl mb-4'><i className='fas fa-question-circle'></i> How do I get verified?</h3>
                    <ProfileGearCategory title='I have a page I want to verify.' contracted>
                        <p>Reach out to <a className='font-bold underline' href='https://twitter.com/Ahadify' target='_blank'>@Ahadify</a> on Twitter with your email address and a link to your page here. We'll verify it as soon as you confirm ownership of the page with an email to the address on file.</p>
                    </ProfileGearCategory>
                    <ProfileGearCategory title='I want to claim an unverified page.' contracted>
                        <p>First, create an account. Then, reach out to <a className='font-bold underline' href='https://twitter.com/Ahadify' target='_blank'>@Ahadify</a> on Twitter with a link to the page that's about you. If we determine the page is using your URL or clearly representing you, we'll transfer ownership of the page to you, then verify it. If a page is clearly a *FAN* branded page and does not prevent you from creating your own official page, we encourage you to make a new page instead.</p>
                    </ProfileGearCategory>
                </ProfileContent>
            </div>
        </React.Fragment>
    );

};

export default verifyInfo;