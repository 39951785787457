import React, {Component} from 'react';
import {withFirebase} from "../components/Firebase";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import ghost from '../utils/GhostAPI';
import BlogCard from "../components/BlogCard/BlogCard";

class GameDetails extends Component {

    state = {
        gameId: null,
        game: null,
        gameCover: null,
        gameUsers: [],
        blogPosts: [],
        notFound: false,
        blogError: null
    };

    componentDidMount() {
        const gameUrl = this.props.match.params.gameUrl;
        this.loadDetails(gameUrl);
    }

    loadGamePosts = async gameUrl => {
        try {
            return await ghost.posts.browse({
                limit: 6,
                filter: 'tag:' + gameUrl.toLowerCase()
            });
        } catch (e) {
            this.setState({
                blogError: e.message
            })
            return [];
        }
    };

    loadDetails = async gameUrl => {

        const gameDocResults = await this.props.firebase.getGameByUrl(gameUrl);

        if (gameDocResults.size === 0) {
            this.setState({notFound: true});
            return;
        }
        const gameDoc = gameDocResults.docs[0];

        const gameUserDocs = await this.props.firebase.getGamePlayers(gameDoc.id);
        const gameCover = await this.props.firebase.getGameCover(gameDoc.id);

        const gameUsers = [];
        gameUserDocs.forEach(doc => {
            gameUsers.push(doc.data());
        });

        const blogPosts = await this.loadGamePosts(gameUrl);

        this.setState({
            gameId: gameDoc.id,
            gameUsers,
            gameCover,
            blogPosts,
            game: gameDoc.data()
        })

    };

    render() {

        if (this.state.notFound) {
            return <Redirect to='/g'/>
        }

        if (!this.state.game) {
            return (
                <div className='py-48 text-center text-3xl'>
                    <i className='fas fa-spinner fa-spin'></i>
                </div>
            );
        }

        return (
            <div className='flex flex-wrap mt-12'>
                <div className='w-1/4 px-2'>
                    {this.state.gameCover ? (
                        <img src={this.state.gameCover} alt={this.state.game.name}
                             className='rounded-lg w-full object-contain' style={{maxHeight: '200px'}}/>
                    ) : (
                        <div className='text-3xl text-center text-gray-600 bg-gray-400 py-24'>
                            <i className='fas fa-gamepad'></i>
                        </div>
                    )}
                    <div className='bg-orange-500 p-6 w-full rounded-lg text-white mt-4'>
                        <h3 className='font-bold text-xl'>{this.state.game.name}</h3>
                        {this.state.game.publisher ? (
                            <p className=''>by <span className='font-semibold'>{this.state.game.publisher}</span></p>
                        ) : null}
                        {this.state.game.downloadUrl ? (
                            <div className='mt-4'>
                                {this.state.game.downloadUrl ? (
                                    <a className='w-full block text-center rounded-lg text-orange-500 font-bold text-lg bg-white hover:bg-gray-100 px-4 py-2'
                                       href={this.state.game.downloadUrl}
                                       target='_blank'>{this.state.game.price && this.state.game.price > 0 ? (
                                        <span><i
                                            className='fas fa-shopping-cart'></i> ${parseFloat(this.state.game.price).toFixed(2)}</span>
                                    ) : (
                                        <span><i className='fas fa-download'></i> Play Free</span>
                                    )} <i
                                        className='fas fa-chevron-right'></i></a>
                                ) : null}
                            </div>
                        ) : null}
                    </div>

                </div>
                <div className='w-3/4 px-2'>
                    <ProfileContent noTopSpacing>
                        <h3 className='font-bold text-xl mb-4'><i
                            className='fas fa-users'></i> Profiles who play {this.state.game.name}...</h3>
                        {this.state.gameUsers.length > 0 ? (
                            <div className='flex flex-wrap'>
                                {this.state.gameUsers.map(profile => {
                                    return (
                                        <div key={profile.url} className='w-1/2 px-2'>
                                            <ProfileContent noTopSpacing hover='orange-100'>
                                                <NavLink to={'/' + profile.url}>
                                                    <div className='text-left flex flex-row items-center'>
                                                        <div className='w-1/3 px-2'>
                                                            <img className='rounded-full w-20 h-20 object-cover'
                                                                 src={profile.profileUrl} alt={profile.handle}/>
                                                        </div>
                                                        <div className='w-2/3 px-2'>
                                                            <h3 className='font-bold text-2xl'>{profile.name}</h3>
                                                            <p className='text-gray-600'>@{profile.handle} {profile.verified ?
                                                                <i className='fas fa-user-check text-orange-500'></i> : null}</p>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </ProfileContent>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className='text-center mt-2 py-12'>
                                <i className='fas fa-users text-5xl'></i>
                                <h3 className='text-xl font-bold'>Nobody here!</h3>
                                <p>Doesn't look like any profiles play {this.state.game.name}!</p>
                            </div>
                        )}
                    </ProfileContent>
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i
                            className='fas fa-book'></i> Our guides for {this.state.game.name}...</h3>
                        {this.state.blogPosts.length > 0 ? (
                            <div className='flex flex-wrap items-center'>
                                {this.state.blogPosts.map(blogPost => {
                                    return (
                                        <div className='w-1/2 px-2 my-2' key={blogPost.id}>
                                            <BlogCard blogPost={blogPost}/>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className='text-center mt-2 py-12'>
                                <i className='fas fa-book text-5xl'></i>
                                <h3 className='text-xl font-bold'>Nothing here!</h3>
                                <p>We don't have any tips &amp; tricks for this game yet!</p>
                                {this.state.blogError ? (
                                    <p className='text-red-500 my-2'>{this.state.blogError}</p>
                                ) : null}
                            </div>
                        )}
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withRouter(withFirebase(GameDetails));