import React from 'react';
import {NavLink} from "react-router-dom";

const footer = props => {

    return(
        <div className='bg-gray-800 text-gray-100 mt-64 pt-12 pb-12 text-center border-t border-gray-300'>
            <div className='max-w-xl mx-auto'>
                <h4 className='text-xl font-bold'>&copy; 2020 MySetup.GG</h4>
                <p>Made by <a className='font-semibold hover:underline' href='https://twitter.com/ahadify' >Ahad</a> &amp; <a className='font-semibold hover:underline' target='_blank' href='https://twitter.com/DevonThome'>@DevonThome</a></p>
                <p className='mt-4 text-sm text-gray-400'><strong>Paid Promotion Notice:</strong><br/>Links on this website might be affiliate or pay-per-click endorsements. These clicks support MySetup.GG and ensure development and hosting stays covered.</p>
            </div>

        </div>
    );

};

export default footer;