import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import * as validator from "validator";
import {withFirebase} from "../components/Firebase";

class NewGame extends Component {

    state = {
        loading: false,
        error: null
    };

    render() {
        return (
            <div>
                <ProfileHero color={this.state.error?'red':'orange'}>
                    <h1 className='text-white font-bold text-5xl'>
                        Create a Game
                    </h1>
                    <p className='text-xl text-white pb-12'>
                        {this.state.error||"Create a game that users can add to their pages."}
                    </p>
                </ProfileHero>
                <div className='-mt-24 max-w-xl mx-auto'>
                    <ProfileContent>
                        <SmartForm
                            fields={{
                                name: {
                                    label: "Game Name",
                                    type: 'text',
                                    placeholder: "Overwatch",
                                    required: true
                                },
                                url: {
                                    label: "URL Slug",
                                    type: 'text',
                                    placeholder: "mysetup.gg/g/...",
                                    hint: "This is what shows in the URL.",
                                    required: true,
                                    maxLength: 16,
                                    validations: [
                                        {
                                            isValid: input => validator.isAlpha(input),
                                            error: "URL slug can only contain letters"
                                        }
                                    ],
                                    changeOnInput: input => {
                                        return input.toLowerCase();
                                    }
                                }
                            }}
                            button={() => {
                                return <span><i className='fas fa-plus'></i> Create Game &amp; Customize...</span>
                            }}
                            loading={this.state.loading}
                            submit={input => {
                                this.setState({loading: true, error: null});
                                this.props.firebase.createGame(input.name, input.url).then(() => {
                                    window.location.href = '/g/' + input.url + "/edit";
                                }).catch(e => {
                                    this.setState({
                                        error: e.message,
                                        loading: false
                                    })
                                });
                            }}
                        />
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withFirebase(NewGame);