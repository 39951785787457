import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import {withFirebase} from "../components/Firebase";
import GameCover from "../components/GameCover/GameCover";
import {NavLink} from "react-router-dom";

class Games extends Component {

    state = {
        games: []
    };

    componentDidMount() {
        this.loadGames();
    };

    loadGames = async () => {
        const gameDocs = await this.props.firebase.getGames();
        const games = [];
        gameDocs.forEach(doc => {
            games.push({
                id: doc.id,
                data: doc.data()
            })
        });
        this.setState({games});
    };

    render() {
        return (
            <div>
                <ProfileHero color={this.state.error ? 'red' : 'orange'}>
                    <h1 className='text-white font-bold text-5xl'>
                        Games
                    </h1>
                    <p className='text-xl text-white pb-12'>
                        {this.state.error || "Games officially endorsed by MySetup.GG"}
                    </p>
                </ProfileHero>
                <div className='-mt-24 max-w-3xl mx-auto'>
                    <ProfileContent>
                        <div className='flex flex-wrap items-center'>
                            {this.state.games.map(game => {
                                return (
                                    <div key={game.id} className='w-1/4 px-2 text-center'>
                                        <NavLink to={'/g/' + game.data.url}>
                                            <GameCover gameId={game.id}/>
                                            <p className='font-semibold my-2'>{game.data.name}</p>
                                        </NavLink>
                                    </div>
                                );
                            })}
                        </div>
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withFirebase(Games);