import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import ProfileGearCategory from "../components/ProfileGearCategory/ProfileGearCategory";
import {withFirebase} from "../components/Firebase";
import {withUser} from "../components/Session";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import socials from "../utils/socials";
import * as moment from 'moment';
import DeviceCard from "../components/DeviceCard/DeviceCard";

class Profile extends Component {

    state = {
        profile: null,
        redirect: false
    };

    componentDidMount() {
        this.loadPage(this.props.match.params.profileUrl)
    }

    loadPage = async url => {
        const page = await this.props.firebase.getProfileByUrl(url);
        if (page.exists){
            this.setState({
                profile: page.data()
            })
        }
        else{
            this.setState({
                redirect: true
            })
        }
    };

    render() {

        if (this.state.redirect){
            return <Redirect to='/'/>
        }

        if (!this.state.profile){
            return (
                <div className='py-48 text-center text-3xl'>
                    <i className='fas fa-spinner fa-spin'></i>
                </div>
            );
        }

        if (!this.state.profile.published && (!this.props.user || this.props.user.uid !== this.state.profile.creatorId)){
            return (
                <div className='my-24 py-12 text-center'>
                    <i className='fas fa-user-times text-5xl py-4'></i>
                    <h4 className='text-3xl font-bold'>Page not yet published.</h4>
                    <p className='text-lg'>The page owner has not yet published their page. Check back later!</p>
                </div>
            );
        }

        return (
            <div>
                {!this.state.profile.published?(
                    <div className='bg-blue-500 px-4 py-2 rounded-lg text-white font-semibold text-center mt-6'>
                        <p><strong>Page not published: </strong>Nobody (except you!) will be able to view it until you publish it. <NavLink className='font-bold underline' to={'/' + this.state.profile.url + "/edit"}>Publish &gt;</NavLink></p>
                    </div>
                ):null}
                <ProfileHero editUrl={this.state.profile.url} editable={this.props.user&&this.props.user.uid===this.state.profile.creatorId}>
                    {this.state.profile.profileUrl ? (
                        <img alt={this.state.profile.name} className='w-48 h-48 object-cover shadow-lg rounded-full inline -mt-16' src={this.state.profile.profileUrl}/>
                    ) : (
                        <i className='fas fa-user-circle text-5xl text-white' style={{fontSize: '7em'}}></i>
                    )}
                    <div className='my-4 text-white'>
                        <h1 className='text-4xl font-bold'>{this.state.profile.name}</h1>
                        <p className='text-2xl'>@{this.state.profile.handle}{this.state.profile.verified?<i className='fas fa-user-check text-white ml-4'></i>:null}</p>
                    </div>
                    {this.state.profile.socials?(
                        <div className='max-w-lg mx-auto flex flex-wrap items-center justify-center mt-8'>
                            {this.state.profile.socials.map(social => {

                                const socialData = socials[social.social];
                                if (!socialData){
                                    return null;
                                }
                                return (
                                    <div style={{transition: 'all 0.2s'}} key={social.social+social.url} className=' w-1/3 px-4'>
                                        <a href={socialData.link + social.url} target='_blank' className='flex flex-row items-center bg-white hover:bg-orange-500 border-2 border-orange-500 hover:border-white hover:text-white'>
                                            <div className='w-1/4 py-2 bg-orange-500 border-t border-l border-b border-white'>
                                                <i className={(socialData.icon) + ' text-white'}></i>
                                            </div>
                                            <div className='w-3/4 text-left pl-4 font-bold text-lg truncate'>
                                                {social.label||social.url}
                                            </div>
                                        </a>
                                    </div>
                                );

                            })}
                        </div>
                    ):null}

                </ProfileHero>
                <div className='flex flex-wrap items-center mt-12'>
                    <div className='w-1/3 px-2'>
                        <div className='bg-white rounded-lg shadow-lg p-4'>
                            <div className='flex flex-row items-center'>
                                <div className='w-1/3 text-4xl text-center'>
                                    <i className='fas fa-clock'></i>
                                </div>
                                <div className='w-2/3 text-left'>
                                    <h3 className='font-semibold text-xl'>{moment(this.state.profile.createdAt.seconds*1000).format('MMM Do, YYYY')}</h3>
                                    <p>Profile Created</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/3 px-2'>
                        <div className='bg-white rounded-lg shadow-lg p-4'>
                            <div className='flex flex-row items-center'>
                                <div className='w-1/3 text-4xl text-center'>
                                    <i className='fas fa-edit'></i>
                                </div>
                                <div className='w-2/3 text-left'>
                                    <h3 className='font-semibold text-xl'>{moment(this.state.profile.updatedAt?this.state.profile.updatedAt.seconds*1000:this.state.profile.createdAt.seconds*1000).fromNow()}</h3>
                                    <p>Last Edited</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.profile.verified?(
                        <div className='w-1/3 px-2'>
                            <div className='bg-white rounded-lg shadow-lg p-4'>
                                <div className='flex flex-row items-center'>
                                    <div className='w-1/3 text-4xl text-center'>
                                        <i className='fas fa-user-check'></i>
                                    </div>
                                    <div className='w-2/3 text-left'>
                                        <h3 className='font-semibold text-xl'>
                                            Verified Owner
                                        </h3>
                                        <p>This page is official.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className='w-1/3 px-2'>
                            <div className='bg-white rounded-lg shadow-lg p-4'>
                                <div className='flex flex-row items-center'>
                                    <div className='w-1/3 text-4xl text-center'>
                                        <i className='fas fa-question-circle'></i>
                                    </div>
                                    <div className='w-2/3 text-left'>
                                        <h3 className='font-semibold text-xl'>
                                            Unverified Owner
                                        </h3>
                                        <p className='text-sm'>Page not verified. <NavLink to='/verify' className='text-orange-500 hover:underline'>Verify It <i className='fas fa-arrow-right'></i></NavLink></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <ProfileContent>
                    <h3 className='font-bold text-xl'><i className='fas fa-trophy'></i> @{this.state.profile.handle}'s Library...</h3>
                    <div className='flex flex-wrap items-center my-2'>
                        {this.state.profile.games.length === 0 ? (
                            <div className='w-full text-center my-6'>
                                <p className='text-red-600'>@{this.state.profile.handle} hasn't added any games yet <i className='far fa-frown'></i></p>
                            </div>
                        ) : (
                            <React.Fragment>
                                {this.state.profile.games.map(gameDetails => {
                                    return (
                                        <div className='w-1/6 px-1 text-center' key={gameDetails.game}>
                                            <img className='my-4 inline' src={gameDetails.coverUrl}/>
                                            <button className='bg-orange-500 hover:bg-orange-400 text-white font-bold px-2 py-1 w-full rounded-lg'>
                                                Details <i className='fas fa-chevron-right'></i>
                                            </button>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </div>
                </ProfileContent>
                <ProfileContent>
                    <h3 className='font-bold text-xl'><i className='fas fa-headphones'></i> @{this.state.profile.handle}'s Gear...</h3>
                    <div className='flex flex-wrap mt-4'>
                        {this.state.profile.devices?this.state.profile.devices.map(deviceId => {
                            return (
                                <div className='w-1/4' key={deviceId}>
                                    <DeviceCard deviceId={deviceId} profileView/>
                                </div>
                            );
                        }):null}
                    </div>
                </ProfileContent>
            </div>
        );
    }

}

export default withRouter(withUser(withFirebase(Profile)));