import React, {Component} from 'react';
import ProfileContent from "../components/ProfileContent/ProfileContent";
import {withFirebase} from "../components/Firebase";
import {NavLink} from "react-router-dom";
import GameCover from "../components/GameCover/GameCover";
import ghost from '../utils/GhostAPI';
import BlogCard from "../components/BlogCard/BlogCard";
import * as moment from 'moment';

class Home extends Component {

    state = {
        recent: [],
        games: [],
        blogPosts: []
    };

    componentDidMount() {
        this.loadDetails();
    }

    loadDetails = async () => {

        const recentDocs = await this.props.firebase.getRecentProfiles();
        const gameDocs = await this.props.firebase.getGames();

        const blogPosts = await ghost.posts.browse({limit: 5});

        const recent = [];
        const games = [];
        recentDocs.forEach(doc => {
            recent.push(doc.data())
        });
        gameDocs.forEach(doc => {
            games.push({
                id: doc.id,
                data: doc.data()
            });
        });
        this.setState({recent, games, blogPosts});

    };

    render() {

        let gameCount = 0;

        return (
            <div>
                <div className='bg-orange-500 w-full'>
                    <div className='text-center text-white py-24'>
                        <h1 className='font-black text-5xl'><i className='fas fa-gamepad'></i> Welcome to MySetup</h1>
                        <p className='text-2xl font-white'>Games, Gear, Configs, &amp; more</p>
                    </div>
                </div>
                <div className='max-w-5xl mx-auto'>
                    <div className='-mt-24'>
                        <ProfileContent>
                            <div className='text-center'>
                                <h3 className='text-2xl font-bold'><i className='fas fa-users'></i> Recently Updated Profiles</h3>
                            </div>
                            <div className='flex flex-wrap mt-16 justify-center'>
                                {this.state.recent.map(profile => {
                                    return (
                                        <div key={profile.url} className='w-1/4 px-2 my-2'>
                                            <div className='bg-orange-200 border border-orange-500 text-orange-600 text-center'>
                                                <img src={profile.profileUrl} alt={profile.handle} className='w-20 h-20 rounded-full object-cover inline shadow-lg -mt-12'/>
                                                <div className='p-2 no-wrap truncate'>
                                                    <h3 className='font-semibold text-lg'>{profile.name}</h3>
                                                    <p className='text-sm'>@{profile.handle}{profile.verified?<i className='fas fa-user-check ml-2'></i>:null}</p>
                                                    <NavLink to={'/' + profile.url} className='block text-white rounded-lg bg-orange-500 hover:bg-orange-400 text-center font-bold px-4 py-2 mt-4'>
                                                        View <i className='fas fa-arrow-right'></i>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ProfileContent>
                        <ProfileContent>
                            <div className='text-center'>
                                <h3 className='text-2xl font-bold'><i className='fas fa-trophy'></i> Browse by Game</h3>
                            </div>
                            <div className='flex flex-wrap my-2 justify-center items-center'>
                                {this.state.games.map(game => {
                                    gameCount++;
                                    if (gameCount > 5){
                                        return null;
                                    }
                                    return (
                                        <div key={game.id} className='w-1/6 px-2 text-center'>
                                            <NavLink to={'/g/' + game.data.url}>
                                                <GameCover gameId={game.id}/>
                                                <p className='font-semibold my-2'>{game.data.name}</p>
                                            </NavLink>
                                        </div>
                                    );
                                })}
                                <div className='w-1/6 px-2 text-center'>
                                    <NavLink to='/games'>
                                        <div className='text-center w-32 mx-auto bg-orange-500 text-white rounded-lg'>
                                            <i className='fas fa-arrow-right text-lg py-24'></i>
                                        </div>
                                        <p className='font-semibold my-2'>View More</p>
                                    </NavLink>
                                </div>
                            </div>
                        </ProfileContent>
                        <ProfileContent>
                            <div className='text-center'>
                                <h3 className='text-2xl font-bold'><i className='fas fa-book'></i> Latest Guides</h3>
                            </div>
                            <div className='flex flex-wrap justify-center items-center mt-4'>
                                {this.state.blogPosts.map(blogPost => {
                                    return (
                                        <div className='w-full px-2 my-2'>
                                            <div className='flex flex-row items-center'>
                                                <div className='w-1/4 px-2'>
                                                    <img src={blogPost.feature_image} className='w-full object-fit'/>
                                                </div>
                                                <div className='w-3/4 px-2'>
                                                    <a href={blogPost.url} target='_blank' className='font-semibold text-xl'>{blogPost.title}</a>
                                                    <p className='text-orange-500 my-1'>Posted {moment(blogPost.published_at).fromNow()}, {blogPost.reading_time>0?blogPost.reading_time:'< 1'} min read</p>
                                                    <p className='text-gray-600'>{blogPost.custom_excerpt||blogPost.excerpt} <a href={blogPost.url} target='_blank' className='text-orange-500 underline'>Read more...</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <a href='https://blog.mysetup.gg' className='text-center text-xl bg-orange-500 hover:bg-orange-400 text-white font-bold px-6 py-3 rounded-lg block w-full mt-6'>View all guides &amp; posts <i className='fas fa-arrow-right'></i></a>
                        </ProfileContent>
                    </div>
                </div>


            </div>
        );
    }

}

export default withFirebase(Home);