import React, {Component} from 'react';
import {withFirebase} from "../components/Firebase";
import {NavLink, withRouter} from "react-router-dom";
import ProfileContent from "../components/ProfileContent/ProfileContent";

class Device extends Component {

    state = {
        deviceId: null,
        device: null,
        deviceCategory: null,
        deviceUsers: []
    };

    componentDidMount() {
        const deviceId = this.props.match.params.deviceId;
        this.loadDetails(deviceId);
    }

    loadDetails = async deviceId => {

        const deviceDoc = await this.props.firebase.getDevice(deviceId);
        const deviceCategoryDoc = await this.props.firebase.getDeviceCategory(deviceDoc.data().categoryId);
        const deviceUserDocs = await this.props.firebase.getDeviceUsers(deviceId);

        const deviceUsers = [];
        deviceUserDocs.forEach(doc => {
            deviceUsers.push(doc.data());
        });

        this.setState({
            deviceId,
            deviceUsers,
            device: deviceDoc.data(),
            deviceCategory: deviceCategoryDoc.data()
        })

    };

    render() {

        if (!this.state.device || !this.state.deviceCategory) {
            return (
                <div className='py-48 text-center text-3xl'>
                    <i className='fas fa-spinner fa-spin'></i>
                </div>
            );
        }

        return (
            <div className='flex flex-wrap mt-12'>
                <div className='w-1/4 px-2'>
                    {this.state.device.imageUrl ? (
                        <img src={this.state.device.imageUrl} alt={this.state.device.modelName}
                             className='rounded-lg w-full object-contain' style={{maxHeight: '200px'}}/>
                    ) : (
                        <div className='text-3xl text-center text-gray-600 bg-gray-400 py-24'>
                            <i className={this.state.deviceCategory.icon}></i>
                        </div>
                    )}
                    <div className='bg-orange-500 p-6 w-full rounded-lg text-white mt-4'>
                        <h3 className='font-bold text-xl'>{this.state.device.modelName}</h3>
                        <p className=''>by <span className='font-semibold'>{this.state.device.manufacturer}</span></p>
                        {this.state.device.buyLinks ? (
                            <div className='mt-4'>
                                {this.state.device.buyLinks.amazon ? (
                                    <a className='w-full block text-center rounded-lg text-orange-500 font-bold text-lg bg-white hover:bg-gray-100 px-4 py-2'
                                       href={this.state.device.buyLinks.amazon} target='_blank'><i
                                        className='fab fa-amazon'></i> Amazon <i
                                        className='fas fa-chevron-right'></i></a>
                                ) : null}
                            </div>
                        ) : null}
                        <div className='border-t border-white mt-6'>
                        </div>
                        <table className='w-full mt-6 text-left'>
                            <tr className='py-1'>
                                <th>Price:</th>
                                <td>${parseFloat(this.state.device.price).toFixed(2)} USD</td>
                            </tr>
                            <tr className='py-1'>
                                <th>Type:</th>
                                <td><i className={this.state.deviceCategory.icon}></i> {this.state.deviceCategory.name}
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
                <div className='w-3/4 px-2'>
                    <ProfileContent noTopSpacing>
                        <h3 className='font-bold text-xl mb-4'><i
                            className='fas fa-users'></i> This {this.state.deviceCategory.name.toLowerCase()} is used
                            by...</h3>
                        {this.state.deviceUsers.length > 0 ? (
                            <div className='flex flex-wrap'>
                                {this.state.deviceUsers.map(profile => {
                                    return (
                                        <div key={profile.url} className='w-1/2 px-2'>
                                            <ProfileContent noTopSpacing hover='orange-100'>
                                                <NavLink to={'/' + profile.url}>
                                                    <div className='text-left flex flex-row items-center'>
                                                        <div className='w-1/3 px-2'>
                                                            <img className='rounded-full w-20 h-20 object-cover'
                                                                 src={profile.profileUrl} alt={profile.handle}/>
                                                        </div>
                                                        <div className='w-2/3 px-2'>
                                                            <h3 className='font-bold text-2xl'>{profile.name}</h3>
                                                            <p className='text-gray-600'>@{profile.handle} {profile.verified ?
                                                                <i className='fas fa-user-check text-orange-500'></i> : null}</p>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </ProfileContent>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className='text-center mt-2 py-12'>
                                <i className='fas fa-users text-5xl'></i>
                                <h3 className='text-xl font-bold'>Nobody here!</h3>
                                <p>Doesn't look like any profiles use this mouse!</p>
                            </div>
                        )}
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withRouter(withFirebase(Device));