import React, {Component} from 'react';
import {withFirebase} from "../Firebase";
import {NavLink} from "react-router-dom";

class DeviceCard extends Component {

    state = {
        device: null,
        deviceCategory: null,
        hide: false
    };

    componentDidMount() {
        this.loadDevice(this.props.deviceId)
    }

    loadDevice = async deviceId => {
        const deviceDoc = await this.props.firebase.getDevice(deviceId);
        if (!deviceDoc.exists) {
            this.setState({hide: true})
        } else {
            const deviceCategoryDoc = await this.props.firebase.getDeviceCategory(deviceDoc.data().categoryId);
            this.setState({device: deviceDoc.data(), deviceCategory: deviceCategoryDoc.data()})
        }
    };

    render() {

        if (this.state.hide) {
            return null;
        }

        if (!this.state.device || !this.state.deviceCategory) {
            return (
                <div className='w-full bg-white text-center shadow-lg p-2 rounded-lg'>
                    <p className='py-12'><i className='fas fa-spinner fa-spin text-2xl'></i></p>
                </div>
            );
        }

        return (
            <div className='w-full bg-white shadow-lg px-4 py-2 rounded-lg my-2'>
                {this.state.device.imageUrl ? (
                    <img src={this.state.device.imageUrl} alt={this.state.device.modelName}
                         className='rounded-lg w-full object-contain' style={{maxHeight: '100px'}}/>
                ) : (
                    <div className='text-xl text-center text-gray-600 bg-gray-400 py-8'>
                        <i className={this.state.deviceCategory.icon}></i>
                    </div>
                )}
                <NavLink to={'/d/' + this.props.deviceId} target='_blank' className='text-lg font-semibold mt-2'>
                    <p className='no-wrap truncate mt-2'>{this.state.device.manufacturer} {this.state.device.modelName}</p>
                </NavLink>
                <p className='py-1 rounded-lg text-gray-500 font-bold'><i
                    className={this.state.deviceCategory.icon}></i> {this.state.deviceCategory.name}</p>
                <div className='text-center my-4'>
                    {this.props.profileView ? (
                        <React.Fragment>
                            {this.state.device.buyLinks&&this.state.device.buyLinks.amazon?(
                                <a href={this.state.device.buyLinks.amazon} target='_blank'
                                         className='text-white bg-orange-500 hover:bg-orange-400 block font-bold rounded-lg w-full px-4 py-2 text-sm'>
                                    <i className='fab fa-amazon'></i> View on Amazon
                                </a>
                            ):null}
                            <NavLink to={'/d/' + this.props.deviceId} target='_blank'
                                     className='text-orange-500 hover:text-orange400 block font-semibold w-full px-4 py-2 text-sm'>
                                Details <i className='fas fa-chevron-right'></i>
                            </NavLink>
                        </React.Fragment>
                    ) : (
                        <button onClick={() => {
                            this.props.removeDevice(this.props.deviceId)
                        }} className='text-red-500 hover:text-red-400 font-semibold w-full px-4 py-2 text-sm'>
                            <i className='fas fa-times'></i> Remove
                        </button>
                    )}
                </div>
            </div>
        );
    }

}

export default withFirebase(DeviceCard);