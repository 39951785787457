import React, {Component} from 'react';
import {withFirebase} from "../Firebase";
import ProfileContent from "../ProfileContent/ProfileContent";
import {NavLink} from "react-router-dom";

class DeviceSearch extends Component {

    state = {
        devices: [],
        categories: [],
        selectedCategory: null,
        search: ''
    };

    componentDidMount() {
        this.loadCategories();
    }

    loadDevices = async () => {
        const results = await this.props.firebase.getDevices(this.state.selectedCategory);
        const devices = [];
        results.forEach(result => {
            devices.push({
                id: result.id,
                data: result.data()
            });
        });
        this.setState({devices})
    };

    loadCategories = async () => {

        const categoryDocs = await this.props.firebase.getDeviceCategories();
        const categories = [];
        categoryDocs.forEach(doc => {
            categories.push({
                id: doc.id,
                data: doc.data()
            })
        });
        this.setState({categories});

    };

    render() {

        let deviceCount = 0;

        return (
            <React.Fragment>
                <div className='flex flex-row items-center'>
                    <div className='w-1/2'>
                        <h5 className='text-xl font-semibold'>Choose Category:</h5>
                    </div>
                    <div className='w-1/2'>
                        <select className='w-full' onChange={e => {

                            if (!e.target.value){
                                return;
                            }

                            this.setState({
                                selectedCategory: e.target.value
                            }, this.loadDevices)

                        }}>
                            <option>Choose Device Category...</option>
                            {this.state.categories.map(category => {
                                return <option key={category.id} value={category.id}>{category.data.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                {this.state.selectedCategory?(
                    <input
                        type='text'
                        className={'w-full my-4 text-gray-800 outline-none bg-gray-200 px-3 py-1 shadow border border-gray-300 text-2xl shadow-xl'}
                        value={this.state.search}
                        placeholder='Search products...'
                        onChange={e => {
                            this.setState({search: e.target.value})
                        }}
                    />
                ):null}
                {this.state.devices.map(device => {

                    const deviceCategory = this.state.categories.find(c => {
                        return c.id === device.data.categoryId
                    });
                    if (!deviceCategory){
                        return null;
                    }

                    if (this.state.search){
                        if (!device.data.modelName.toLowerCase().includes(this.state.search.toLowerCase()) && !device.data.manufacturer.toLowerCase().includes(this.state.search.toLowerCase())){
                            return null;
                        }
                    }

                    deviceCount++;
                    if (deviceCount > 10){
                        return null;
                    }

                    return (
                        <ProfileContent key={device.id} topSpacing='6'>
                            <div className='flex flex-row items-center'>
                                <div className='w-1/4 px-2 text-center'>
                                    {device.data.imageUrl?(
                                        <img style={{maxHeight: '100px'}} src={device.data.imageUrl} className='w-full object-contain' alt={device.data.modelName}/>
                                    ):(
                                        <div className='text-center text-xl bg-gray-400 text-gray-600 rounded-lg py-10'>
                                            <i className={deviceCategory.data.icon}></i>
                                        </div>
                                    )}
                                </div>
                                <div className='w-2/4 px-2'>
                                    <h2 className='text-xl font-bold'>{device.data.modelName}</h2>
                                    <p>by <span className='font-semibold'>{device.data.manufacturer}</span></p>
                                </div>
                                <div className='w-1/4 px-2'>
                                    {this.props.devices.includes(device.id) ? (
                                        <button onClick={()=>{this.props.removeDevice(device.id)}} className='bg-green-500 hover:bg-green-400 px-2 py-1 text-white font-semibold text-lg rounded-lg w-full'>
                                            <i className='fas fa-check'></i> Added
                                        </button>
                                    ) : (
                                        <button onClick={()=>{this.props.addDevice(device.id)}}  className='bg-orange-500 hover:bg-orange-400 px-2 py-1 text-white font-semibold text-lg rounded-lg w-full'>
                                            I use this!
                                        </button>
                                    )}
                                    <NavLink to={'/d/' + device.id} target='_blank' className='text-sm w-full text-center block mt-2 text-orange-500 hover:text-orange-400 text-sm'>
                                        Details <i className='fas fa-chevron-right'></i>
                                    </NavLink>
                                </div>
                            </div>
                        </ProfileContent>
                    );
                })}
            </React.Fragment>

        );
    }
}

export default withFirebase(DeviceSearch);