import React, {Component} from 'react';
import {withFirebase} from "../Firebase";

class GameCover extends Component {

    state = {
        cover: null
    };

    componentDidMount() {
        this.loadCover();
    }

    loadCover = async () => {
        const url = await this.props.firebase.getGameCover(this.props.gameId);
        this.setState({cover: url});
    };

    render() {
        return (
            <div>
                {this.state.cover?(
                    <img alt='' className='my-4 inline h-48 object-cover' src={this.state.cover}/>
                ):(
                    <div className='text-center w-32 mx-auto bg-gray-400 text-gray-600 rounded-lg'>
                        <i className='fas fa-gamepad text-lg py-20'></i>
                    </div>
                )}
            </div>
        );
    }

}

export default withFirebase(GameCover);