import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import {withFirebase} from "../components/Firebase";
import {Redirect} from "react-router-dom";

class NewDevice extends Component {

    state = {
        error: null,
        loading: false,
        categories: [],
        redirect: null
    };

    componentDidMount() {
        this.reloadCategories();
    }

    reloadCategories = async () => {

        const categoryDocs = await this.props.firebase.getDeviceCategories();

        const categories = [];
        categoryDocs.forEach(doc => {
            categories.push({
                id: doc.id,
                data: doc.data()
            });
        });
        this.setState({categories})

    };

    render() {

        if (this.state.redirect){
            return <Redirect to={'/d/' + this.state.redirect + "/edit"}/>
        }

        const deviceCategoryMap = {};
        this.state.categories.forEach(category => {
            deviceCategoryMap[category.id] = category.data.name
        });

        return (
            <div>
                <ProfileHero color={this.state.error?'red':'orange'}>
                    <h1 className='text-white font-bold text-5xl'>
                        Create a Device
                    </h1>
                    <p className='text-xl text-white pb-12'>
                        {this.state.error||"Create a game that users can add to their pages."}
                    </p>
                </ProfileHero>
                <div className='-mt-24 max-w-xl mx-auto'>
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i className='fas fa-list'></i> Add Device Category</h3>
                        <SmartForm
                            fields={{
                                name: {
                                    label: "Name",
                                    placeholder: "Monitor, Mouse, Keyboard, etc.",
                                    type: 'text',
                                    required: true
                                },
                                icon: {
                                    label: "Icon",
                                    placeholder: "fas fa-mouse",
                                    hint: "The icon code to use for the text icon.",
                                    hintLink: {
                                        text: "View them here",
                                        url: "https://fontawesome.com/icons?d=gallery&m=free"
                                    },
                                    required: true,
                                    validations: [
                                        {
                                            isValid: input => {
                                                return input.startsWith("fa")
                                            },
                                            error: "Invalid icon provided. Make sure to copy the full icon code (fab fa-twitter, fas fa-clock, etc.)"
                                        }
                                    ]
                                }
                            }}
                            loading={this.state.loading}
                            button={() => {
                                return <span><i className='fas fa-plus'></i> Add Category</span>
                            }}
                            submit={async (input, resetFields) => {
                                try {
                                    await this.setState({loading: true})
                                    await this.props.firebase.createDeviceCategory(input.icon, input.name);
                                    this.reloadCategories();
                                    await this.setState({loading: false});
                                    resetFields();
                                }
                                catch (e) {
                                    await this.setState({loading: false})
                                    this.setState({error: e.message})
                                    console.error(e);
                                }
                            }}
                        />
                    </ProfileContent>
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i className='fas fa-mouse'></i> Add Device</h3>
                        <SmartForm
                            fields={{
                                category: {
                                    label: 'Category',
                                    type: 'select',
                                    options: deviceCategoryMap,
                                    hint: "This is the type of product you're adding",
                                    required: true
                                },
                                manufacturer: {
                                    label: "Manufacturer",
                                    type: 'text',
                                    hint: "The company that makes this product.",
                                    required: true
                                },
                                modelName: {
                                    label: "Model Name",
                                    type: 'text',
                                    hint: "The name of this product. (Blade, DeathAdder, etc.)",
                                    required: true
                                },
                                price: {
                                    label: "Retail Price (USD)",
                                    type: 'number',
                                    hint: "Retail price, without sales or discounts, in USD.",
                                    required: true
                                }
                            }}
                            loading={this.state.loading}
                            button={() => {
                                return <span><i className='fas fa-plus'></i> Add Device</span>
                            }}
                            submit={async input => {
                                try {
                                    await this.setState({loading: true})
                                    const createdDoc = await this.props.firebase.createDevice(input.category, input.manufacturer, input.modelName, input.price);
                                    this.setState({
                                        redirect: createdDoc.id
                                    })
                                }
                                catch (e) {
                                    await this.setState({loading: false});
                                    this.setState({error: e.message});
                                    console.error(e);
                                }
                            }}
                        />
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withFirebase(NewDevice);