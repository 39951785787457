import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import * as validator from "validator";
import {withFirebase} from "../components/Firebase";
import {Redirect, withRouter} from "react-router-dom";
import ProfileGearCategory from "../components/ProfileGearCategory/ProfileGearCategory";

class EditDevice extends Component {

    state = {
        categories: [],
        loading: false,
        error: null,
        device: null,
        deviceId: null,
        redirect: false,
        cover: null
    };

    fileSelected = async files => {

        if (files.length === 1){
            const file = files[0];
            await this.props.firebase.setDeviceImage(this.state.deviceId, file);
            window.location.reload();
        }

    };

    componentDidMount() {
        const deviceId = this.props.match.params.deviceId;
        this.loadDetails(deviceId);
    }

    loadDetails = async deviceId => {

        const deviceDoc = await this.props.firebase.getDevice(deviceId);
        const deviceCategories = await this.props.firebase.getDeviceCategories();
        const categories = [];
        deviceCategories.forEach(doc => {
            categories.push({
                id: doc.id,
                data: doc.data()
            });
        });

        this.setState({
            deviceId,
            device: deviceDoc.data(),
            categories
        })

    };

    render() {

        if (this.state.device === null){
            if (this.state.redirect){
                return <Redirect to='/'/>
            }
            return (
                <div className='py-48 text-center text-3xl'>
                    <i className='fas fa-spinner fa-spin'></i>
                </div>
            );
        }

        const deviceCategoryMap = {};
        this.state.categories.forEach(category => {
            deviceCategoryMap[category.id] = category.data.name
        });

        return (
            <div>
                <ProfileHero color={this.state.error?'red':'orange'}>
                    <h1 className='text-white pb-12 font-bold text-5xl'>
                        Edit: {this.state.device.manufacturer + " " +this.state.device.modelName}
                    </h1>
                </ProfileHero>
                <div className='-mt-24 max-w-3xl mx-auto'>
                    <ProfileContent>
                        <div className='flex flex-wrap'>
                            <div className='w-1/3 px-2 text-center'>
                                <h3 className='font-bold text-xl mb-4'><i className='fas fa-image'></i> Cover Image</h3>
                                <label>
                                    {this.state.device.imageUrl ? (
                                        <img src={this.state.device.imageUrl} alt={this.state.device.modelName} className='hover:border-2 border-red-500 rounded-lg shadow-lg w-3/4 inline-block cursor-pointer'/>
                                    ) : (
                                        <div className='bg-gray-300 cursor-pointer rounded-lg hover:bg-gray-400 w-3/4 inline-block'>
                                            <i className='fas fa-image text-xl py-32'></i>
                                        </div>
                                    )}
                                    <input onChange={e => {this.fileSelected(e.target.files)}} accept='image/png' type='file' className='hidden'/>
                                </label>
                                <p className='text-gray-600 mt-4 font-semibold text-lg'>Select a .png that will be the device's cover image.</p>
                            </div>
                            <div className='w-2/3 px-2'>
                                <h3 className='font-bold text-xl mb-4'><i className='fas fa-edit'></i> Basic Info</h3>
                                <SmartForm
                                    fields={{
                                        category: {
                                            label: 'Category',
                                            type: 'select',
                                            options: deviceCategoryMap,
                                            hint: "This is the type of product you're adding",
                                            required: true,
                                            value: this.state.device.categoryId
                                        },
                                        manufacturer: {
                                            label: "Manufacturer",
                                            type: 'text',
                                            hint: "The company that makes this product.",
                                            required: true,
                                            value: this.state.device.manufacturer
                                        },
                                        modelName: {
                                            label: "Model Name",
                                            type: 'text',
                                            hint: "The name of this product. (Blade, DeathAdder, etc.)",
                                            required: true,
                                            value: this.state.device.modelName
                                        },
                                        price: {
                                            label: "Price (USD)",
                                            type: 'number',
                                            hint: "Retail price, without sales or discounts, in USD.",
                                            required: true,
                                            value: this.state.device.price
                                        }
                                    }}
                                    button={() => {
                                        return <span><i className='fas fa-check'></i> Save</span>
                                    }}
                                    loading={this.state.loading}
                                    submit={input => {
                                        this.setState({loading: true, error: null});
                                        this.props.firebase.updateDevice(this.state.deviceId, {
                                            categoryId: input.category,
                                            manufacturer: input.manufacturer,
                                            modelName: input.modelName,
                                            price: input.price
                                        }).then(() => {
                                            window.location.reload();
                                        }).catch(e => {
                                            this.setState({
                                                error: e.message,
                                                loading: false
                                            })
                                        });
                                    }}
                                />
                            </div>
                        </div>

                    </ProfileContent>
                    <ProfileContent>
                        <h3 className='font-bold text-xl mb-4'><i className='fas fa-shopping-cart'></i> Purchase Links</h3>
                        <SmartForm
                            fields={{
                                amazon: {
                                    label: 'Amazon Affiliate',
                                    hint: "Affiliate link to purchase product from Amazon.com",
                                    placeholder: 'amazon.com/...',
                                    value: this.state.device.buyLinks&&this.state.device.buyLinks.amazon?this.state.device.buyLinks.amazon:''
                                }
                            }}
                            loading={this.state.loading}
                            button={() => {
                                return <span><i className='fas fa-check'></i> Save</span>
                            }}
                            submit={async input => {
                                this.setState({loading: true});
                                const device = {...this.state.device};
                                let buyLinks = {};
                                if (device.buyLinks){
                                    buyLinks = device.buyLinks;
                                }
                                buyLinks.amazon = input.amazon;
                                device.buyLinks = buyLinks;
                                this.setState({device});
                                await this.props.firebase.updateDevice(this.state.deviceId, {
                                    buyLinks: device.buyLinks
                                })
                                this.setState({loading: false})
                            }}
                        />
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withRouter(withFirebase(EditDevice));