import React, {Component} from 'react';
import ProfileHero from "../components/ProfileHero/ProfileHero";
import ProfileContent from "../components/ProfileContent/ProfileContent";
import SmartForm from "../components/SmartForm/SmartForm";
import * as validator from "validator";
import {withUser} from "../components/Session";
import {withFirebase} from "../components/Firebase";
import {Redirect} from "react-router-dom";

class NewPage extends Component {

    state = {
        error: null,
        loading: false,
        redirect: null
    };

    createPage = async fields => {

        this.setState({loading: true, error: null});

        try {
            const existing = await this.props.firebase.getProfileByUrl(fields.url);
            if (existing.exists) {
                throw new Error("This URL is already taken! Choose another.")
            }

            const newPage = await this.props.firebase.createProfile(fields.url, fields.name, fields.handle);

            await this.setState({redirect: fields.url});

        }
        catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })
        }
    };

    render() {

        if (this.state.redirect){
            return <Redirect to={'/' + this.state.redirect}/>
        }

        return (
            <div>
                <ProfileHero color={this.state.error?'red':'orange'}>
                    <h1 className='text-white font-bold text-5xl'>
                        Create a Profile
                    </h1>
                    <p className='text-xl text-white pb-12'>
                        {this.state.error||"Create a new profile page!"}
                    </p>
                </ProfileHero>
                <div className='-mt-24 max-w-xl mx-auto'>
                    <ProfileContent>
                        <p className='mb-2'>This profile will be associated with your <strong>{this.props.user?this.props.user.email:null}</strong> account.</p>
                        <SmartForm
                            fields={{
                                url: {
                                    label: "Page URL",
                                    placeholder: 'mysetup.gg/...',
                                    hint: "This is the link people will visit to go to your page.",
                                    required: true,
                                    maxLength: 24,
                                    validations: [
                                        {
                                            isValid: input => validator.isAlphanumeric(input),
                                            error: "URL can only contain letters and numbers"
                                        }
                                    ],
                                    changeOnInput: input => input.toLowerCase()
                                },
                                name: {
                                    label: "Name",
                                    placeholder: "John Doe",
                                    hint: "This is the real name, or first name of who this profile is for.",
                                    required: true,
                                    maxLength: 16
                                },
                                handle: {
                                    label: "Handle",
                                    placeholder: "Ahadify",
                                    hint: "The social handle or username this person is most known by",
                                    required: true,
                                    maxLength: 24
                                }
                            }}
                            loading={this.state.loading}
                            button={() => {
                                return <span><i className='fas fa-plus'></i> Create Page</span>
                            }}
                            submit={input => this.createPage(input)}
                        />
                    </ProfileContent>
                </div>
            </div>
        );
    }

}

export default withUser(withFirebase(NewPage));