import React from 'react';
import {withFirebase} from "../Firebase";
import {AuthUserContext} from "./index";

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {

        constructor(props){
            super(props);
            this.state = {
                authUser: null
            }
        }

        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(user => {
                this.setState({authUser: user})
            })
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props}/>
                </AuthUserContext.Provider>
            );
        }
    }
    return withFirebase(WithAuthentication);
};

export default withAuthentication;