import React from 'react';

const profileContent = props => {
    return (
        <div className={(!props.noTopSpacing?'mt-' + (props.topSpacing||'12 '):'') + (props.hover?'hover:bg-' + props.hover+' cursor-pointer ':'') + ' bg-white rounded-lg shadow-lg p-6'}>
            {props.children}
        </div>
    );
};

export default profileContent;