import React, {Component} from 'react';
import hotkeys from 'hotkeys-js';
import keycodes from "../../utils/keycodes";

class SmartForm extends Component {

    state = {
        input: {},
        errors: {},
        formErrors: 0,
        keyScope: null
    };

    componentDidMount() {
        this.placeDefaultValues();
        hotkeys("*", e => {
            if (this.state.keyScope){
                e.preventDefault();
                const formFieldId = this.state.keyScope;
                const kc = hotkeys.getPressedKeyCodes();
                const input = {...this.state.input};
                input[this.state.keyScope] = kc;
                this.setState({input});

                if (this.state.errors[formFieldId]) {
                    const errors = {...this.state.errors};
                    delete errors[formFieldId];
                    this.setState({errors});
                }
            }
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.placeDefaultValues(nextProps);
    }

    placeDefaultValues = (props = this.props) => {
        const formFields = Object.keys(props.fields);
        const input = {...this.state.input};
        for (let x = 0; x < formFields.length; x++) {
            const formFieldId = formFields[x];
            const formField = props.fields[formFieldId];
            if (formField.value && !input.value) {
                input[formFieldId] = formField.value;
            }
        }
        this.setState({input})
    };

    preSubmit = e => {

        e.preventDefault();

        if (this.props.loading) {
            return;
        }

        const formFields = Object.keys(this.props.fields);
        let formErrors = 0;
        const errors = {};
        for (let x = 0; x < formFields.length; x++) {
            const formFieldId = formFields[x];
            const formField = this.props.fields[formFieldId];
            try {
                if (!this.isValid(formField, this.state.input[formFieldId])) {
                }
            } catch (e) {
                formErrors++;
                errors[formFieldId] = e.message;
            }

        }

        if (formErrors === 0) {
            this.props.submit(this.state.input, () => {
                this.setState({input: {}, errors: {}, formErrors: 0})
            });
        } else {
            this.setState({errors, formErrors});
        }
    };

    isValid = (formField, input) => {

        if (formField.required && (!input || input.length === 0)) {
            throw new Error(formField.label + " is required");
        }

        if (formField.maxLength && input.length > formField.maxLength) {
            throw new Error(formField.label + " can't exceed " + formField.maxLength + " characters")
        }

        if (formField.type === 'select' && (!input || input === 'defaultValue')){
            throw new Error(formField.label + " is required");
        }

        if (formField.validations) {
            for (let x = 0; x < formField.validations.length; x++) {
                const validation = formField.validations[x];
                if (!validation.isValid(input, this.state.input)) {
                    throw new Error(validation.error);
                }
            }
        }

        return true;
    };

    render() {
        return (
            <form onSubmit={e => {
                this.preSubmit(e);
            }}>

                {Object.keys(this.props.fields).map(formFieldId => {
                    const formField = this.props.fields[formFieldId];
                    const disabled = formField.disabled || this.props.loading;

                    const fieldError = this.state.errors[formFieldId] || null;

                    let shortcutValue = "";
                    if (formField.type === 'shortcut' && this.state.input[formFieldId]){
                        const translatedCodes = [...this.state.input[formFieldId]].map(keyCode => {
                            return keycodes[keyCode] || ""
                        });
                        console.log(translatedCodes);
                        shortcutValue = translatedCodes.join(" + ");
                    }

                    return (
                        <div key={formFieldId}
                             className='flex flex-row items-center mt-4 pb-4 border-b border-gray-300'>
                            <div className='w-1/3 text-left'>
                                <h5 className='font-bold text-lg'>{formField.label}</h5>
                            </div>
                            <div className='w-2/3 text-left'>
                                {formField.type === 'toggle' ? (
                                    <div className='relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in'>
                                        <input
                                            name={"toggle" + formFieldId}
                                            id={"toggle" + formFieldId}
                                            type="checkbox"
                                            className={(this.state.input[formFieldId] ? 'right-0 border-orange-400' : '') + " absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"}
                                            checked={this.state.input[formFieldId] || false}
                                            onClick={() => {
                                                const input = {...this.state.input};
                                                if (input[formFieldId]) {
                                                    input[formFieldId] = !input[formFieldId];
                                                } else {
                                                    input[formFieldId] = true;
                                                }
                                                this.setState({input})
                                            }}
                                            disabled={disabled}
                                        />
                                        <label htmlFor={"toggle" + formFieldId} className={(disabled?'opacity-50 cursor-not-allowed ':'') + (this.state.input[formFieldId]?'bg-orange-400':'') + " block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"}></label>
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {formField.type === 'select' ? (
                                            <select
                                                value={this.state.input[formFieldId]||null}
                                                onChange={e => {
                                                    const input = {...this.state.input};
                                                    input[formFieldId] = e.target.value;
                                                    this.setState({input});
                                                    if (this.state.errors[formFieldId]) {
                                                        const errors = {...this.state.errors};
                                                        delete errors[formFieldId];
                                                        this.setState({errors});
                                                    }
                                                }}
                                                disabled={disabled}
                                                className={(disabled ? 'opacity-50 cursor-not-allowed ' : '') + 'w-full text-' + (fieldError ? 'red' : 'gray') + '-800 outline-none bg-gray-200 px-3 py-1 shadow border border-' + (fieldError ? 'red' : 'gray') + '-300 text-xl'}
                                            >
                                                <option value="defaultValue">Choose {formField.label}...</option>
                                                {Object.keys(formField.options).map(optionKey => {
                                                    return (
                                                        <option value={optionKey}>{formField.options[optionKey]}</option>
                                                    );
                                                })}
                                            </select>
                                        ) : (
                                            <React.Fragment>
                                                {formField.type === 'shortcut' ? (
                                                    <input
                                                        type='text'
                                                        placeholder={this.state.keyScope&&this.state.keyScope===formFieldId?'Press Key(s)...':'Click to record...'}
                                                        value={shortcutValue}
                                                        onClick={() => {
                                                            this.setState({keyScope: formFieldId});
                                                        }}
                                                        onBlur={() => {
                                                            this.setState({keyScope: null})
                                                        }}
                                                        readOnly
                                                        disabled={disabled}
                                                        className={(disabled ? 'opacity-50 cursor-not-allowed ' : '') + 'w-full text-' + (fieldError ? 'red' : 'gray') + '-800 outline-none bg-' + (this.state.keyScope&&this.state.keyScope===formFieldId?'purple':'gray') + '-200 px-3 py-1 shadow border border-' + (fieldError ? 'red' : 'gray') + '-300 text-xl'}
                                                    />
                                                ) : (
                                                    <input
                                                        type={formField.type}
                                                        placeholder={formField.placeholder}
                                                        value={this.state.input[formFieldId] || ""}
                                                        maxLength={formField.maxLength}
                                                        onChange={e => {
                                                            const input = {...this.state.input};
                                                            let value = e.target.value;

                                                            if (formField.changeOnInput) {
                                                                value = formField.changeOnInput(value);
                                                            }

                                                            input[formFieldId] = value;
                                                            this.setState({input});

                                                            if (this.state.errors[formFieldId]) {
                                                                const errors = {...this.state.errors};
                                                                delete errors[formFieldId];
                                                                this.setState({errors});
                                                            }

                                                        }}
                                                        disabled={disabled}
                                                        className={(disabled ? 'opacity-50 cursor-not-allowed ' : '') + 'w-full text-' + (fieldError ? 'red' : 'gray') + '-800 outline-none bg-gray-200 px-3 py-1 shadow border border-' + (fieldError ? 'red' : 'gray') + '-300 text-xl'}
                                                    />
                                                )}
                                            </React.Fragment>

                                        )}
                                    </React.Fragment>
                                )}

                                {formField.hint || fieldError ? (
                                    <p className={'text-sm ' + (fieldError ? 'text-red-600' : 'text-gray-600') + ' mt-2'}>
                                        {fieldError ? <span><i
                                            className='fas fa-times-circle mr-2'></i>{fieldError}</span> : formField.hint} {!fieldError&&formField.hint&&formField.hintLink?<a href={formField.hintLink.url} target='_blank' className='ml-1 text-orange-500 underline hover:text-orange-400'>{formField.hintLink.text} &gt;</a>:null}
                                    </p>
                                ) : null}

                            </div>
                        </div>
                    );
                })}

                {this.props.button ? (
                    <button
                        className={'mt-4 px-4 py-2 text-white font-bold bg-orange-500 ' + (this.props.loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-400') + ' rounded-lg w-full'}>
                        {this.props.loading ? <i className='fas fa-spinner fa-spin'></i> : this.props.button()}
                    </button>
                ) : null}

            </form>
        );
    }

}

export default SmartForm;