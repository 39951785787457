import React from 'react';
import Profile from "./containers/Profile";
import Navbar from "./components/Navbar/Navbar";
import {Route, Switch, withRouter} from "react-router-dom";
import Register from "./containers/Register";
import NewPage from "./containers/NewPage";
import Account from "./containers/Account";
import Footer from "./components/Footer/Footer";
import withAuthentication from "./components/Session/withAuthentication";
import NewGame from "./containers/NewGame";
import EditGame from "./containers/EditGame";
import EditProfile from "./containers/EditProfile";
import VerifyInfo from './containers/VerifyInfo';
import NewDevice from "./containers/NewDevice";
import Device from './containers/Device';
import EditDevice from "./containers/EditDevice";
import Games from './containers/Games';
import GameDetails from "./containers/GameDetails";
import Home from "./containers/Home";

const app = props => {
  return (
    <div className="App">
        <Navbar/>
        <div className={(props.location.pathname!=='/'?'max-w-5xl mx-auto':'')}>
            <Switch>
                <Route path='/g/:url/edit' component={EditGame}/>
                <Route path='/g/:gameUrl' component={GameDetails}/>
                <Route path='/d/new' component={NewDevice}/>
                <Route path='/d/:deviceId/edit' component={EditDevice}/>
                <Route path='/d/:deviceId' component={Device}/>
                <Route path='/g/new' component={NewGame}/>
                <Route path='/games' component={Games}/>
                <Route path='/g' component={Games}/>
                <Route path='/register' component={Register}/>
                <Route path='/new' component={NewPage}/>
                <Route path='/verify' component={VerifyInfo}/>
                <Route path='/account' component={Account}/>
                <Route path='/:profileUrl/edit' component={EditProfile}/>
                <Route path='/:profileUrl' component={Profile}/>
                <Route path='/' component={Home}/>
            </Switch>
        </div>
        <Footer/>

    </div>
  );
}

export default withRouter(withAuthentication(app));
