import React from 'react';
import {NavLink} from "react-router-dom";

const profileHero = props => {

    return (
        <React.Fragment>
            {props.editable ? (
                <div className='mt-12'>
                    <NavLink className='cursor-pointer px-4 py-2 bg-orange-500 hover:bg-orange-400 rounded-lg text-white font-semibold' to={'/' + props.editUrl + "/edit"}><i className='fas fa-edit'></i> Edit</NavLink>
                </div>
            ) : null}
            <div className={(props.editable?'mt-4':'mt-12') + ' text-center bg-' + (props.color || 'orange') + '-500 rounded-lg p-12'}>
                {props.children}
            </div>
        </React.Fragment>
    );

};

export default profileHero;