import React from 'react';

const AuthUserContext = React.createContext(null);

export const withUser = Component => props => {
    return (
        <AuthUserContext.Consumer>
            {user => <Component {...props} user={user}/>}
        </AuthUserContext.Consumer>
    );
};

export default AuthUserContext;