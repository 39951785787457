import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import SmartForm from "../SmartForm/SmartForm";
import {withFirebase} from "../Firebase";

class Login extends Component {

    state = {
        error: null,
        loading: false
    };

    toggleForgot = () => {
        this.setState({forgot: !this.state.forgot})
    };

    render() {
        return (
            <React.Fragment>
                <div onClick={this.props.close} className='fixed top-0 left-0 z-20 bg-orange-500 w-full h-full opacity-75'>

                </div>
                <div style={{left: '50%', transform: "translateX(-50%)"}} className='fixed z-30 mt-24 w-full max-w-xl'>
                    <div className='bg-white rounded-lg shadow-xl text-center px-6 py-3'>
                        <h1 className='text-5xl font-bold'><i className={'fas fa-' + (this.state.forgot?'question-circle':'gamepad')}></i></h1>
                        <h1 className='text-3xl font-bold'>{this.state.forgot?'Forgot Password?':'Sign In'}</h1>
                        {this.state.error?<p className='text-red-500 my-4 font-bold'><i className='fas fa-times-circle'></i> {this.state.error}</p>:null}
                        {this.state.forgot?(
                            <SmartForm
                                fields={{
                                    email: {
                                        label: "Email",
                                        type: "text",
                                        placeholder: "me@example.com",
                                        required: true,
                                        hint: "We'll send you instructions to recover your account"
                                    }
                                }}
                                button={() => {
                                    return <span><i className='fas fa-envelope'></i> Send Password Reset</span>
                                }}
                                loading={this.state.loading}
                                submit={input => {
                                    this.setState({loading: true, error: null});
                                    this.props.firebase.sendPasswordReset(input.email).then(() => {
                                        this.props.close()
                                    }).catch(e => {
                                        this.setState({
                                            error: e.message,
                                            loading: false
                                        })
                                    });
                                }}
                            />
                        ):(
                            <SmartForm
                                fields={{
                                    email: {
                                        label: "Email",
                                        type: "text",
                                        placeholder: "me@example.com",
                                        required: true
                                    },
                                    password: {
                                        label: "Password",
                                        type: "password",
                                        required: true
                                    }
                                }}
                                button={() => {
                                    return <span><i className='fas fa-user'></i> Sign In</span>
                                }}
                                loading={this.state.loading}
                                submit={input => {
                                    this.setState({loading: true, error: null});
                                    this.props.firebase.signIn(input.email, input.password).then(() => {
                                        this.props.close()
                                    }).catch(e => {
                                        this.setState({
                                            error: e.message,
                                            loading: false
                                        })
                                    });
                                }}
                            />
                        )}
                        <button onClick={this.toggleForgot} className='mb-4 px-4 py-2 text-orange-500 hover:underline font-bold rounded-lg w-full'>
                            {this.state.forgot?'Back to Login':'Forgot Password?'}
                        </button>
                    </div>
                </div>
            </React.Fragment>

        );
    }

}

export default withFirebase(Login);